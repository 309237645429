import React from "react"
import PropTypes from "prop-types"
import LabelIcon from "../labelIcon"
import Flex from "../flex"
import Typography from "../typography"
import DoctorsAvatar from "../doctorsAvatar"
import AddressIcon from "../../assets/svgs/address.svg"
import PhoneIcon from "../../assets/svgs/phone.svg"
import WhatsAppIcon from "../../assets/svgs/whatsapp2.svg"

const WorkplacesPlace = ({
  name,
  address,
  phone,
  whatsapp,
  url,
  doctors,
  color,
  hideDoctors,
}) => (
  <Flex
    child
    flexGtSm={33}
    flexSm={50}
    flex={100}
    style={{ margin: "1.5rem 0" }}
  >
    <Typography
      color="primary"
      variant="h4"
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </Typography>
    <LabelIcon icon={AddressIcon}>
      <Typography color={color} variant="bodyLarge" pre>
        {address}
      </Typography>
    </LabelIcon>
    <Flex align="flex-start">
      {phone && (
        <Flex child flex="none">
          <LabelIcon icon={PhoneIcon}>
            <Typography color={color} variant="bodyLarge">
              {phone} {whatsapp && '/'}
            </Typography>
          </LabelIcon>
        </Flex>
      )}
      {whatsapp && (
        <Flex child flex="none">
          <LabelIcon icon={WhatsAppIcon}>
            <a
              href={`https://wa.me/${whatsapp.url}${whatsapp.text ? `?text=${whatsapp.text}` : ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography color={color} variant="bodyLarge">
                {whatsapp.label}
              </Typography>
            </a>
          </LabelIcon>
        </Flex>
      )}
    </Flex>
    {!hideDoctors && <DoctorsAvatar enabled={doctors} style={{ maxWidth: 280 }} />}
  </Flex>
)

WorkplacesPlace.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  whatsapp: PropTypes.objectOf(PropTypes.any),
  url: PropTypes.string.isRequired,
  doctors: PropTypes.objectOf(PropTypes.any).isRequired,
  color: PropTypes.string,
  hideDoctors: PropTypes.bool,
}

WorkplacesPlace.defaultProps = {
  color: undefined,
  hideDoctors: false,
  whatsapp: null,
}

export default WorkplacesPlace
