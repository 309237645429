import React, { memo } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Flex from "../flex"
import allDoctors from "../../api/doctors"
import { useImagesFluid } from "../../hooks/useImages"

const findImage = ({ images, name }) =>
  images.find(({ node }) => name === node.fluid.originalName)

const DoctorsAvatar = ({ enabled, style }) => {
  const doctors = allDoctors.filter(doctor => enabled[doctor.slug])
  const images = useImagesFluid({ names: doctors.map(doctor => doctor.image) })
  return (
    <Flex style={style} gap={0.8} flexWrap="wrap">
      {doctors.map(doctor => (
        <Flex child flex="none">
          <Link to={`/equipe/${doctor.slug}`}>
            <Img
              fluid={findImage({ images, name: doctor.image }).node.fluid}
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center top",
                width: "100%",
              }}
              style={{ width: "60px", height: "60px", borderRadius: "100%" }}
              title={doctor.name}
              alt={doctor.name}
            />
          </Link>
        </Flex>
      ))}
    </Flex>
  )
}

DoctorsAvatar.propTypes = {
  enabled: PropTypes.objectOf(PropTypes.any).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
}

DoctorsAvatar.defaultProps = {
  style: undefined,
}

export default memo(DoctorsAvatar)
