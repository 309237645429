import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Flex from "../../components/flex"
import Typography from "../../components/typography"
import hospitals from "../../api/hospitals"
import clinics from "../../api/clinics"
import Image from "../../assets/images/places.png"
import Place from "../../components/workplacesPlace"

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Workplaces = props => {
  return (
    <Layout {...props}>
      <SEO
        title="GastroCirúrgica em Florianopólis/SC"
        description="Os cirurgiões da GastroCirúrgica atendem nos hospitais Imperial Hospital de Caridade, Hospital Baia Sul Medical Center, Hospital Unimed Grande Florianópolis"
      />
      <Section padding="1rem 0 2rem 0">
        <Container>
          <Flex flexWrap="wrap" align="center" justify="space-between">
            <Flex child flex={100} flexGtSm={35}>
              <Typography variant="h1" component="h2" color="secondary">
                Onde atendemos
              </Typography>
              <Typography variant="h5" component="h1" color="primary" pre>
                {`Atendemos em consultórios, clínicas e hospitais na grande Florianópolis/SC`}
              </Typography>
              <Typography variant="bodyLarge">
                A GastroCirúrgica conta com uma equipe experiente e capacitada
                para diagnosticar, fazer exames, realizar tratamento clínico e
                cirúrgico de enfermidades relacionadas ao aparelho digestivo.
              </Typography>
            </Flex>
            <Flex child flex={100} flexGtSm={40}>
              <StyledImage
                src={Image}
                alt="Hospitais em que GastroCírurgica atende"
              />
            </Flex>
          </Flex>
        </Container>
      </Section>
      <Section background="secondary">
        <Container>
          <Typography variant="h2" component="h3" color="light">
            Hospitais
          </Typography>
          <Flex flexWrap="wrap">
            {hospitals.map(place => (
              <Place color="light" {...place} />
            ))}
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container>
          <Typography variant="h2" component="h3" color="secondary">
            Clínicas
          </Typography>
          <Flex flexWrap="wrap">
            {clinics.map(place => (
              <Place {...place} />
            ))}
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Workplaces
